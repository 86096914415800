import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setDrawerAlert, setStage } from 'slices/drawer'
import {
  REGISTRATION_DATA_STAGES,
  CONTACT_TYPE,
  EVENTS,
  DEFAULT_ERROR_MESSAGE,
  FIREBASE_EVENTS,
  EVENT_STATUS,
} from 'app-constants'
import { Button, Card, RadioButton } from 'components'
import { setCustomerMainContact } from 'slices/customer'
import { postEvent, useFirebase } from 'hooks'
import './style.scss'

export function EmailSelectDefault() {
  const [error, setError] = useState(false)
  const [emailsList, setEmailsList] = useState([])
  const [emailIndexSelected, setEmailIndexSelected] = useState()
  const {
    selected: { id: idContract },
  } = useSelector(({ contract }) => contract)
  const { sendEvent } = useFirebase()
  const {
    info: { contacts },
  } = useSelector(state => state.customer)
  const dispatch = useDispatch()

  useEffect(() => {
    function manipulateContacts() {
      const emailsArr = contacts.filter(item => item.contactType === CONTACT_TYPE.EMAIL)

      setEmailsList(emailsArr.filter(email => !email.principal && email.confirmed))
    }

    manipulateContacts()
  }, [contacts])

  async function handleSubmit() {
    postEvent(idContract, EVENTS.EMAIL_UPDATE)

    if (emailIndexSelected >= 0) {
      try {
        sendEvent(FIREBASE_EVENTS.CLICK_ON_SELECT_MAIN_EMAIL)

        await dispatch(
          setCustomerMainContact({ contactId: emailsList[emailIndexSelected].contactId })
        ).unwrap()
        sendEvent(FIREBASE_EVENTS.ALTER_MAIN_EMAIL_SUCCESS, EVENT_STATUS.SUCCESS)

        dispatch(
          setDrawerAlert({ severity: 'success', title: `Você atualizou o seu e-mail principal!` })
        )
      } catch (error) {
        sendEvent(
          FIREBASE_EVENTS.ALTER_MAIN_EMAIL_ERROR,
          EVENT_STATUS.ERROR,
          error?.response?.status
        )

        dispatch(setDrawerAlert({ severity: 'error', title: DEFAULT_ERROR_MESSAGE }))
      }

      dispatch(setStage(REGISTRATION_DATA_STAGES.LIST))
    } else {
      setError(true)
    }
  }

  function handleSelectEmail(index) {
    setEmailIndexSelected(index)
    setError(false)
  }

  function renderValidEmailsList(emailsList) {
    return !emailsList.length ? (
      <span>
        Só é possível alterar o e-mail principal para e-mails confirmados, os e-mails não
        confirmados não são exibidos na lista.
      </span>
    ) : (
      emailsList.map((item, index) => (
        <Card
          className={`card ${emailIndexSelected === index ? '-selected' : ''} ${
            error ? '-error' : ''
          }`}
          onClick={() => handleSelectEmail(index)}
          key={String(index)}
        >
          <RadioButton id={index} checked={emailIndexSelected === index} />
          <p className='text'>{item.contact}</p>
        </Card>
      ))
    )
  }

  return (
    <div className='email-select-default-component'>
      <div className='content'>
        <h3 className='title'>Selecionar o e-mail principal</h3>
        <div className='list'>{renderValidEmailsList(emailsList)}</div>
      </div>
      {!!emailsList.length && (
        <Button type='button' color='primary' size='large' onClick={handleSubmit}>
          Definir e-mail principal
        </Button>
      )}
    </div>
  )
}
