import React from 'react'
import { useDispatch } from 'react-redux'
import { setStage } from 'slices/modal'
import { fetchContacts } from 'slices/customer'
import { sendConfirmationCodeEmail } from 'hooks'
import { emailRgx } from 'utils'
import { Input } from 'components/input'
import { Button } from 'components/button'
import {
  CONTACT_UPDATE_STAGES,
  DEFAULT_ERROR_MESSAGE,
  INVALID_EMAIL,
  ALREADY_CONFIRM_RESPONSE_STATUS_CODE,
} from '../../constants'
import './style.scss'

export function EmailStage({ data, setData, setErrorMessage }) {
  const dispatch = useDispatch()

  function getIsValidEmail() {
    const { email } = data
    const isValid = emailRgx.test(email)

    return isValid
  }

  async function handleClickButton() {
    try {
      const isValidEmailRegexp = getIsValidEmail()

      if (isValidEmailRegexp) {
        const body = { contact: data.email }
        await sendConfirmationCodeEmail(body)

        dispatch(setStage(CONTACT_UPDATE_STAGES.CONFIRMATION))
      } else setErrorMessage(INVALID_EMAIL)
    } catch (error) {
      console.error(error)
      if (error.response?.status === ALREADY_CONFIRM_RESPONSE_STATUS_CODE) {
        dispatch(setStage(CONTACT_UPDATE_STAGES.SUCCESS))
        dispatch(fetchContacts())
      } else setErrorMessage(DEFAULT_ERROR_MESSAGE)
    }
  }

  function handleOnChange({ target }) {
    const { name, value } = target

    setData({ ...data, [name]: value })
    setErrorMessage('')
  }

  return (
    <div className='contact-update-email-stage'>
      <span className='title'>Atualização de Contato</span>
      <span className='description -first'>Legal! Seu telefone foi cadastrado com sucesso!</span>
      <span className='description'>
        Agora informe seu e-mail e após clicar em continuar, você receberá um novo código de
        confirmação, mas atenção, será no e-mail informado, ok?!
      </span>
      <div className='container-input'>
        <span className='input-title'>Informe o seu melhor e-mail:</span>
        <Input
          name='email'
          className='input-email'
          placeholder='E-mail'
          onChange={handleOnChange}
        />
      </div>
      <div className='container-button'>
        <Button className='button' color='primary' onClick={handleClickButton}>
          Continuar
        </Button>
      </div>
    </div>
  )
}
