import { configureStore } from '@reduxjs/toolkit'
import modalReducer from './slices/modal'
import customerReducer from './slices/customer'
import contractReducer from './slices/contract'
import alertReducer from './slices/alert'
import drawerReducer from './slices/drawer'
import chatReducer from './slices/chat'
import massiveReducer from './slices/massive'
import wifiReducer from './slices/wifi'

export const store = configureStore({
  reducer: {
    modal: modalReducer,
    customer: customerReducer,
    contract: contractReducer,
    alert: alertReducer,
    drawer: drawerReducer,
    chat: chatReducer,
    massive: massiveReducer,
    wifi: wifiReducer,
  },
})
