import React, { useEffect } from 'react'
import { EVENTS, ROUTES } from 'app-constants'
import { ValidateToken } from 'components'
import { postEvent } from 'hooks'

export function FirstAccessValidateToken() {
  useEffect(() => {
    postEvent(null, EVENTS.PASSWORD_UPDATE)
  }, [])
  return (
    <ValidateToken
      title='Primeiro acesso'
      nextStep={ROUTES.FIRST_ACCESS_PASSWORD.PATH}
      prevStep={ROUTES.FIRST_ACCESS.PATH}
    />
  )
}
