/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  key: '',
  stage: 'initial',
  history: [],
  alert: null,
}

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setDrawer: (state, action) => {
      state.key = action.payload
      state.stage = 'initial'
      state.history = []
      state.alert = null
    },
    setStage: (state, action) => {
      state.history.push(state.stage)
      state.stage = action.payload
    },
    goBack: state => {
      const stage = state.history.pop()
      state.stage = stage
    },
    clearHistory: state => {
      state.history = []
    },
    clearDrawerAlert: state => {
      state.alert = null
    },
    setDrawerAlert: (state, action) => {
      state.alert = action.payload
    },
  },
})

export const {
  setDrawer,
  setStage,
  goBack,
  clearHistory,
  clearDrawerAlert,
  setDrawerAlert,
} = drawerSlice.actions

export default drawerSlice.reducer
