import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FaqInitial, FaqCategory } from './components'
import { STAGES } from './constants'

export function FaqDrawer() {
  const [faq, setFaq] = useState([])
  const [category, setCategory] = useState('')
  const { stage } = useSelector(({ drawer }) => drawer)
  return stages({ faq, setFaq, category, setCategory })[stage]
}

function stages({ ...props }) {
  return {
    [STAGES.INITIAL]: <FaqInitial {...props} />,
    [STAGES.CATEGORY]: <FaqCategory {...props} />,
  }
}
