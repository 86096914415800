import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDrawer, setDrawerAlert, setStage } from 'slices/drawer'
import {
  DEFAULT_ERROR_MESSAGE,
  ACTIVATION_PENDING_STAGES,
  EVENTS,
  FIREBASE_EVENTS,
  EVENT_STATUS,
} from 'app-constants'
import { postEvent, putCancelServiceOrder, useFirebase } from 'hooks'
import { Button } from 'components'
import './style.scss'

export function CancelConfirmation({ serviceOrder }) {
  const [isLoading, setIsLoading] = useState(false)
  const { osId: serviceOrderId } = serviceOrder
  const dispatch = useDispatch()
  const { id: contractId } = useSelector(({ contract }) => contract.selected)
  const { sendEvent } = useFirebase()

  async function handleRequestCancel() {
    setIsLoading(true)
    sendEvent(FIREBASE_EVENTS.SERVICE_ORDER_CANCEL_CONFIRM)

    try {
      const params = {
        contractId,
        serviceOrderId,
      }

      await putCancelServiceOrder(params, params)
      postEvent(contractId, EVENTS.CLICK_ON_CANCEL_OS)
      sendEvent(FIREBASE_EVENTS.SERVICE_ORDER_CANCEL_SUCCESS, EVENT_STATUS.SUCCESS)
      setIsLoading(false)
      dispatch(setStage(ACTIVATION_PENDING_STAGES.CANCEL_SUCCESS))
    } catch (error) {
      const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
      console.error(error)
      sendEvent(
        FIREBASE_EVENTS.SERVICE_ORDER_CANCEL_ERROR,
        EVENT_STATUS.ERROR,
        error?.response?.status
      )
      setIsLoading(false)
      dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
    }
  }

  function handleCancel() {
    dispatch(setDrawer(''))
  }

  function renderButton() {
    return (
      <div className='links'>
        <Button
          className='button'
          color='primary'
          size='large'
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => handleRequestCancel()}
        >
          Confirmar
        </Button>
        <Button
          className='button'
          color='primary'
          size='large'
          outlined
          onClick={() => handleCancel()}
        >
          Cancelar
        </Button>
      </div>
    )
  }

  return (
    <div className='cancel-confirmation'>
      <div className='content'>
        <h3 className='title'>Cancelamento de reparo</h3>
        <div className='info-confirmation'>
          <p className='text'>Você solicitou o cancelamento de reparo.</p>
          <p className='text margin'>Clique no botão para confirmar</p>
        </div>
      </div>
      {renderButton()}
    </div>
  )
}
