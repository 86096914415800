import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EVENTS } from 'app-constants'
import { GenericCard } from 'components'
import { postEvent } from 'hooks'
import { activateContractHero } from 'slices/contract'
import { setAlert } from 'slices/alert'

export function HeroCard({ className, title, image, description }) {
  const dispatch = useDispatch()
  const idContract = useSelector(state => state.contract.selected.id)
  const hasHeroActive = useSelector(state => state.contract.selected.hasHeroActive)
  const hasHeroAvailable = useSelector(state => state.contract.selected.hasHeroAvailable)

  async function handleActivateHero() {
    postEvent(idContract, EVENTS.CLICK_ON_HERO)

    if (!hasHeroActive) {
      try {
        const heroLicense = await dispatch(activateContractHero(idContract)).unwrap()

        heroLicense &&
          window.open(
            `https://fshero.com.br/ativarsumicity/?licenca=${heroLicense}`,
            'hero',
            'noreferrer,noopener'
          )
      } catch (error) {
        const { message } = error
        dispatch(setAlert({ severity: 'error', title: message }))
      }
    }
  }

  if (!hasHeroAvailable) return null

  return (
    <GenericCard
      template='large'
      className={className}
      title={title}
      image={image}
      onClick={handleActivateHero}
      buttonLabel={hasHeroActive ? 'Ativado' : 'Ativar'}
      disabled={hasHeroActive}
    >
      {description}
    </GenericCard>
  )
}
