import React, { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { uploadFile, postEvent, useFirebase } from 'hooks'
import { setDrawerAlert } from 'slices/drawer'
import { setModal } from 'slices/modal'
import {
  EVENTS,
  DEFAULT_ERROR_MESSAGE,
  RIBEIRAO_PRETO_UNITY,
  RIBEIRAO_PRETO_WHATS_APP,
  PHONE_NUMBER,
  FIREBASE_EVENTS,
  EVENT_STATUS,
} from 'app-constants'
import { STATICS } from 'statics'
import { ContextLoader } from 'components/context-loader'
import { Button } from 'components/button'
import './style.scss'

const STATUS = {
  INITIAL: 'initial',
  SUCCESS: 'success',
  ERROR: 'error',
}

export function FileUploadModal() {
  const { selected: selectedContract } = useSelector(({ contract }) => contract)
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState(STATUS.INITIAL)
  const inputFileEl = useRef(null)
  const { sendEvent } = useFirebase()
  const dispatch = useDispatch()

  function getPhoneNumber() {
    if (selectedContract?.unity === RIBEIRAO_PRETO_UNITY) {
      return RIBEIRAO_PRETO_WHATS_APP
    }

    return PHONE_NUMBER
  }

  async function handleSubmit() {
    setIsLoading(true)
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SELECT_FILE)

    try {
      const file = inputFileEl.current.files[0]

      await uploadFile(selectedContract.id, file)
      postEvent(selectedContract.id, EVENTS.SEND_FILES)
      sendEvent(FIREBASE_EVENTS.SEND_FILE_SUCCESS, EVENT_STATUS.SUCCESS)
      setStatus(STATUS.SUCCESS)
    } catch (error) {
      const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE

      console.error(error)
      setStatus(STATUS.ERROR)
      sendEvent(FIREBASE_EVENTS.SEND_FILE_ERROR, EVENT_STATUS.ERROR, error?.response?.status)
      dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
    }

    setIsLoading(false)
  }

  function renderLoading() {
    return <ContextLoader fixed={false} />
  }

  function handleOpenChat() {
    postEvent(selectedContract.id, EVENTS.TALK_ATTENDANT_ON_SUBMISSION_PAYMENT)
    dispatch(setModal({ key: '' }))
    window.SigmaChat.showChat()
  }

  const content = {
    [STATUS.INITIAL]: {
      icon: <STATICS.ROUNDED_RECEIPT />,
      backgroundColor: 'blue',
      text: `*Arquivos suportados: Imagem e PDF\n*Tamanho Máximo: 4Mb`,
      title: 'Envio de arquivo',
      button: (
        <label className='file'>
          Selecionar arquivo
          <input
            name='file'
            className='input'
            ref={inputFileEl}
            onChange={handleSubmit}
            type='file'
            accept='image/*,pdf/*'
          />
        </label>
      ),
    },
    [STATUS.SUCCESS]: {
      icon: <STATICS.DONE />,
      backgroundColor: 'green',
      title: 'Anexo enviado com sucesso',
      button: (
        <>
          <Button
            className='button'
            color='primary'
            size='large'
            onClick={() => dispatch(setModal({ key: '' }))}
          >
            Ok
          </Button>
          <Button className='button' color='primary' size='large' onClick={() => handleOpenChat()}>
            Falar com atendimento
          </Button>
        </>
      ),
    },
    [STATUS.ERROR]: {
      icon: <STATICS.CLOSE />,
      backgroundColor: 'red',
      text: `Lembre-se de enviar um formato de arquivo válido (imagem ou PDF) ao tentar novamente ou se preferir ligue na central de atendimento ${getPhoneNumber()}, atendemos todos dos dias, 24 horas.`,
      title: 'Houve um erro inesperado e não foi concluída a sua solicitação',
      button: (
        <Button
          className='button'
          color='primary'
          size='large'
          onClick={() => dispatch(setModal({ key: '' }))}
        >
          Ok, entendi
        </Button>
      ),
    },
  }

  function renderContent() {
    return (
      <>
        <div className={`round -${content[status].backgroundColor}`}>
          <div className='icon'>{content[status].icon}</div>
        </div>
        <h3 className='title'>{content[status].title}</h3>
        <p className='text'>{content[status].text}</p>
        {content[status].button}
      </>
    )
  }

  return <div className='file-upload-modal'>{isLoading ? renderLoading() : renderContent()}</div>
}
