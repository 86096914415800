import { URL_FILES } from '../common/envs'
import { EVENTS } from '../common/events'

export const DOCUMENTS_PAGE_LABELS = [
  {
    name: 'Termos',
    items: [
      {
        urlFile: `${URL_FILES}termo_de_aceite_confirmacao_da_solicitacao_de_portabilidade.pdf`,
        name: 'Termo de Aceite',
        description: 'Confirmação da solicitação de portabilidade',
        event: EVENTS.CLICK_ON_DOWNLOAD_ACCEPTANCE_TERM_DOCUMENT,
      },
      {
        urlFile: `${URL_FILES}formulario_transferencia_assinatura.pdf`,
        name: 'Transferência de Assinatura',
        description: 'Formulário de Transferência de Assinatura',
        event: EVENTS.CLICK_ON_DOWNLOAD_SUBSCRIPTION_TRANSFER_DOCUMENT,
      },
      {
        urlFile: `${URL_FILES}troca-titularidade/TROCA-DE-TITULARIDADE-MOB.pdf`,
        name: 'Troca de titularidade',
        description: 'Formulário de troca de titularidade',
        event: EVENTS.CLICK_ON_DOWNLOAD_OWNERSHIP_CHANGE_DOCUMENT,
      },
    ],
  },
]
