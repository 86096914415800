import React from 'react'
import { Switch } from 'react-router-dom'
import { RouteWrapper } from './route-wrapper'
import { routes } from './routes'

export function Routes() {
  return (
    <Switch>
      {routes.map(route => (
        <RouteWrapper key={route.path} {...route} />
      ))}
    </Switch>
  )
}
