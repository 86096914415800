export const SCHEDULING_SHIFT_LABEL = {
  MORNING: 'Manhã',
  AFTERNOON: 'Tarde',
}

export const SCHEDULE_TYPE = {
  TURN: 'TURN',
  TIME_FRAME: 'TIME_FRAME',
}

export const SHIFTS = {
  MORNING: 'MORNING',
  AFTERNOON: 'AFTERNOON',
}
