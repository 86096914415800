import React from 'react'
import { EVENTS, TV_PLAN_CARDS } from 'app-constants'
import { postEvent } from 'hooks'
import { TvPlanCard } from '../tv-plan'
import './style.scss'

export function GigaTvCard({ disabled }) {
  async function handleClick(tvPlan, idContract) {
    postEvent(idContract, EVENTS.CLICK_ON_SVA_GIGA_TV)
    if (tvPlan && tvPlan.urlDocument) {
      return window.open(tvPlan.urlDocument, '_blank').focus()
    }
  }

  return (
    <TvPlanCard
      disabled={disabled}
      tvPlanName={TV_PLAN_CARDS.GIGA_TV}
      handleClick={handleClick}
      buttonLabel='SAIBA MAIS'
    />
  )
}
