import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { STATICS } from 'statics'
import { clearDrawerAlert, goBack, setDrawer } from 'slices/drawer'
import { Alert } from 'components/alert'
import { DrawerTemplate } from './templates'
import './style.scss'

export function Drawer() {
  const [isOpen, setIsOpen] = useState(false)
  const { key, history, alert } = useSelector(({ drawer }) => drawer)
  const dispatch = useDispatch()

  useEffect(() => {
    setIsOpen(!!key)
  }, [key])

  function handleClose() {
    dispatch(setDrawer(''))
  }

  function handleCloseAlert() {
    dispatch(clearDrawerAlert())
  }

  function handleReturn() {
    dispatch(goBack())
  }

  function renderBackButton() {
    return (
      <button
        type='button'
        className='icon -left'
        onClick={handleReturn}
        data-cy='button-back-drawer'
      >
        <STATICS.ARROW_BACK />
      </button>
    )
  }

  return (
    <>
      <div className={`drawer-component ${isOpen ? '-open' : ''}`} data-testid='drawer-component'>
        <div className='header'>
          {history.length > 0 && renderBackButton()}
          <button
            type='button'
            className='icon -right'
            onClick={handleClose}
            data-cy='button-close-drawer'
          >
            <STATICS.CLOSE />
          </button>
        </div>
        <div className='content'>
          {!!alert && <Alert className='alert' {...alert} onClose={handleCloseAlert} />}
          {DrawerTemplate()[key]}
        </div>
      </div>
      {isOpen && <div className='drawer-overlay' onClick={handleClose} />}
    </>
  )
}
