import React from 'react'
import { useDispatch } from 'react-redux'
import { setStage } from 'slices/modal'
import { fetchContacts } from 'slices/customer'
import { sendConfirmationCodeSms } from 'hooks'
import { formatPhoneToDB } from 'utils'
import { Input } from 'components/input'
import { Button } from 'components/button'
import {
  CONTACT_UPDATE_STAGES,
  DEFAULT_ERROR_MESSAGE,
  PHONE_SIZE_ERROR_MESSAGE,
  ALREADY_CONFIRM_RESPONSE_STATUS_CODE,
  PHONE_SIZE,
} from '../../constants'
import './style.scss'

export function PhoneStage({ data, setData, setErrorMessage }) {
  const dispatch = useDispatch()

  async function handleClickSubmit() {
    try {
      const formattedPhone = formatPhoneToDB(data.phone)

      if (formattedPhone.length !== PHONE_SIZE) {
        setErrorMessage(PHONE_SIZE_ERROR_MESSAGE)
      } else {
        const body = { contact: formattedPhone, isWhatsapp: data.whatsappConfirmation }
        await sendConfirmationCodeSms(body)

        setData({ ...data, phone: formattedPhone })
        dispatch(setStage(CONTACT_UPDATE_STAGES.CONFIRMATION))
      }
    } catch (error) {
      console.error(error)
      if (error.response?.status === ALREADY_CONFIRM_RESPONSE_STATUS_CODE) {
        dispatch(setStage(CONTACT_UPDATE_STAGES.EMAIL))
        dispatch(fetchContacts())
      } else setErrorMessage(DEFAULT_ERROR_MESSAGE)
    }
  }

  function handleInputChange({ target }) {
    const { value, name } = target

    setData({ ...data, [name]: value })
    setErrorMessage('')
  }

  function handleCheckboxChange() {
    const newValue = !data.whatsappConfirmation

    setData({ ...data, whatsappConfirmation: newValue })
  }

  return (
    <div className='contact-update-phone-stage'>
      <span className='title'>Atualização de Contato</span>
      <span className='description'>
        Olá! Seus dados precisam ser atualizados! As etapas serão simples e rápidas, basta preencher
        os campos conforme os passos a seguir:
      </span>
      <span className='description -middle'>
        Para iniciar, informe seu telefone celular e após clicar em continuar você receberá um
        código de confirmação.
      </span>
      <span className='description'>
        Ah! Aproveite e confirme se podemos utilizar esse número para informações via whatsApp.
      </span>
      <div className='container-input'>
        <span className='input-title'>Informe o seu celular:</span>
        <Input
          mask='(99) 99999-9999'
          onChange={handleInputChange}
          name='phone'
          className='input-phone'
          placeholder='( ) _____ - ____'
        />
        <div className='container-checkbox'>
          <Input
            onChange={handleCheckboxChange}
            name='whatsapp'
            className='input-whatsapp'
            label=' Desejo salvar este número como um contato de WhatsApp.'
            type='checkbox'
          />
        </div>
        <div className='container-button'>
          <Button className='button' color='primary' onClick={handleClickSubmit}>
            Continuar
          </Button>
        </div>
      </div>
    </div>
  )
}
