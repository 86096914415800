import React, { useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { setModal } from 'slices/modal'
import { setDrawer, setStage } from 'slices/drawer'
import { STATICS } from 'statics'
import { BRAND_DISPLAY_NAME, EVENTS, FIREBASE_EVENTS } from 'app-constants'
import { postEvent, useFirebase } from 'hooks'
import { ContractSelect } from 'components/contract-select'
import { DropdownMenu } from './components'
import './style.scss'

export function Header() {
  const dispatch = useDispatch()
  const [keycloak] = useKeycloak()
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [newMessagesLength, setNewMessagesLength] = useState(0)
  const { messages } = useSelector(({ customer }) => customer)
  const selectedContract = useSelector(state => state.contract.selected)
  const contractId = useSelector(state => state.contract.selected?.id)
  const { sendEvent } = useFirebase()

  useEffect(() => {
    if (messages?.contentUnread) {
      const length = messages.contentUnread.length

      setNewMessagesLength(length)
    }
  }, [messages.contentUnread])

  function handleClick({ drawer, modal, stage, event, fireBaseEvent }) {
    setIsOpenMenu(false)

    !!drawer && dispatch(setDrawer(drawer))
    !!stage && dispatch(setStage(stage))
    !!modal && dispatch(setModal({ key: modal }))

    if (event) postEvent(contractId, EVENTS[event])

    if (fireBaseEvent) sendEvent(fireBaseEvent)
  }

  function handleLogoutClick() {
    localStorage.removeItem('SUMICITY_SESSION_TIME')
    return keycloak?.logout()
  }

  function renderComponent() {
    return (
      <header className='header-component'>
        <div className='container'>
          <div className='wrapper'>
            <button
              type='button'
              className='menu'
              onClick={() => setIsOpenMenu(true)}
              data-cy='button-menu-header'
            >
              <STATICS.MENU alt='Menu' />
            </button>
            <Link to='/' className='link'>
              <img className='logo' src={STATICS.LOGO} alt={`${BRAND_DISPLAY_NAME} logo`} />
            </Link>
          </div>
          <div className='wrapper'>
            <ContractSelect className='select' />
            <button
              type='button'
              className={`notifications ${newMessagesLength > 0 ? '-new' : ''}`}
              data-length={newMessagesLength}
              onClick={() =>
                handleClick({
                  drawer: 'messages',
                  fireBaseEvent: FIREBASE_EVENTS.CLICK_ON_OPEN_NOTIFICATIONS,
                })
              }
            >
              <STATICS.NOTIFICATION alt={`${newMessagesLength} Notificações`} />
            </button>
            <button type='button' className='button -logout' onClick={handleLogoutClick}>
              sair
            </button>
          </div>
        </div>
        <div className={`menu ${isOpenMenu ? '-open' : ''}`}>
          <DropdownMenu
            isOpenMenu={isOpenMenu}
            closeMenuHandler={() => setIsOpenMenu(false)}
            handleClick={handleClick}
            keycloak={keycloak}
          />
        </div>
      </header>
    )
  }

  return !!selectedContract && renderComponent()
}
