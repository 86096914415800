import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { setDrawer, setDrawerAlert, setStage } from 'slices/drawer'
import {
  DEFAULT_ERROR_MESSAGE,
  ACTIVATION_PENDING_STAGES,
  EVENTS,
  SCHEDULING_SHIFT_LABEL,
  SERVICE_ORDER_TYPES,
  FIREBASE_EVENTS,
  EVENT_STATUS,
} from 'app-constants'
import { postEvent, updateServiceOrder, useFirebase } from 'hooks'
import { Button } from 'components'
import { formatDate } from 'utils'
import './style.scss'

export function RescheduleActivationConfirmation({ bookingSelected }) {
  const [isLoading, setIsLoading] = useState(false)
  const {
    bookingCode,
    date,
    reference,
    turn,
    contractId,
    serviceOrderId,
    timeFrames: { initialTimeFrame, finalTimeFrame },
  } = bookingSelected
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()

  async function handleRequestVisit() {
    sendEvent(FIREBASE_EVENTS.SERVICE_ORDER_RESCHEDULE_CONFIRM)
    setIsLoading(true)

    try {
      const params = {
        date: format(date, 'yyyy-MM-dd'),
        shift: turn,
        code: bookingCode,
        reference,
        contractId,
        serviceOrderType: SERVICE_ORDER_TYPES.ACTIVATION,
      }

      await updateServiceOrder(serviceOrderId, params)
      postEvent(contractId, EVENTS.RESCHEDULE_ACTIVATION)
      sendEvent(FIREBASE_EVENTS.SERVICE_ORDER_RESCHEDULE_SUCCESS, EVENT_STATUS.SUCCESS)
      setIsLoading(false)

      dispatch(setStage(ACTIVATION_PENDING_STAGES.SCHEDULING_SUCCESS))
    } catch (error) {
      const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
      console.error(error)
      sendEvent(
        FIREBASE_EVENTS.SERVICE_ORDER_RESCHEDULE_ERROR,
        EVENT_STATUS.ERROR,
        error?.response?.status
      )
      setIsLoading(false)
      dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
    }
  }

  function handleCancel() {
    dispatch(setDrawer(''))
  }

  function renderButton() {
    return (
      <div className='links'>
        <Button
          className='button'
          color='primary'
          size='large'
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => handleRequestVisit()}
        >
          Solicitar
        </Button>
        <Button
          className='button'
          color='primary'
          size='large'
          outlined
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => handleCancel()}
        >
          Cancelar
        </Button>
      </div>
    )
  }

  return (
    <div className='reschedule-activation-confirmation'>
      <div className='content'>
        <h3 className='title'>{'Reagendamento da\n instalação'}</h3>
        <div className='info-confirmation'>
          <p className='text'>Você solicitou o reagendamento da instalação.</p>
          <p className='text margin'>Confira o resumo da solicitação:</p>
          <div className='container-reschedule'>
            <div className='row'>
              <h4 className='info-title'>Data de agendamento:</h4>
              <p>{formatDate(date)}</p>
            </div>
            <div className='row'>
              <h4 className='info-title'>Turno:</h4>
              <p>
                {SCHEDULING_SHIFT_LABEL[turn]} {initialTimeFrame} - {finalTimeFrame}
              </p>
            </div>
            {reference && (
              <div className='row'>
                <h4 className='info-title'>Referência:</h4>
                <p>{reference}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {renderButton()}
    </div>
  )
}
