import React, { useState } from 'react'
import * as Yup from 'yup'
import { useKeycloak } from '@react-keycloak/web'
import { useForm, changePasswordOnFirstAccess, postEvent } from 'hooks'
import {
  MESSAGES,
  NEITHER_WHITESPACE_NOR_COMMA_PATTERN,
  DEFAULT_ERROR_MESSAGE,
  EVENTS,
  MINIMUM_PASSWORD_LENGTH,
  MAXIMUM_PASSWORD_LENGTH,
} from 'app-constants'
import { Input, Button, Alert, PasswordForce } from 'components'
import { ALERT_ERROR_DEFAULT_MESSAGE, PASSWORD_RESET_FORM } from './constants'
import './style.scss'

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required(MESSAGES.REQUIRED)
    .min(MINIMUM_PASSWORD_LENGTH, MESSAGES.PASSWORD_MIN_LENGTH)
    .max(MAXIMUM_PASSWORD_LENGTH, MESSAGES.PASSWORD_MAX_LENGTH)
    .test(
      'newPassword',
      'A senha não pode conter espaços ou vírgulas',
      value => value && NEITHER_WHITESPACE_NOR_COMMA_PATTERN.test(value)
    ),
  confirmationNewPassword: Yup.string()
    .required(MESSAGES.REQUIRED)
    .oneOf([Yup.ref('newPassword'), null], MESSAGES.PASSWORDS_DONT_MATCH),
})

export function PasswordReset() {
  const [error, setError] = useState(null)
  const [alertError, setAlertError] = useState(null)
  const [keycloak] = useKeycloak()

  const form = useForm({
    initialValues: PASSWORD_RESET_FORM,
    validationSchema,
    onSubmit: handleSubmit,
  })

  async function handleSubmit(values) {
    try {
      await changePasswordOnFirstAccess(values)

      setError(null)
      postEvent(null, EVENTS.PASSWORD_UPDATE_FIRST_ACCESS)
      localStorage.removeItem('SUMICITY_SESSION_TIME')
      keycloak.logout({ redirectUri: window.location.origin })
    } catch (error) {
      console.error(error)
      setError(null)
      const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE

      setAlertError({ title: ALERT_ERROR_DEFAULT_MESSAGE, text: errorMessage })
    }
  }

  function handleAlertClose() {
    setAlertError(null)
  }

  return (
    <div className='password-reset-page'>
      {!!alertError && (
        <Alert
          className='alert'
          severity='error'
          size='large'
          title={alertError.title}
          text={alertError.text}
          onClose={handleAlertClose}
        />
      )}
      <form className='form' onSubmit={form.handleSubmit}>
        <h1 className='title'>Crie sua nova senha</h1>
        <h3 className='subtitle'>Crie sua nova senha de acesso:</h3>
        <div className='rules'>
          <ul>
            <li>Sua senha deve conter: </li>
            <li>
              Entre {MINIMUM_PASSWORD_LENGTH} e {MAXIMUM_PASSWORD_LENGTH} caracteres.
            </li>
            <li>Ao menos uma letra e um número.</li>
            <li>Não possuir espaços ou vírgulas.</li>
          </ul>
        </div>
        <Input
          className='password'
          name='newPassword'
          label='Nova senha'
          type='password'
          maxLength={MAXIMUM_PASSWORD_LENGTH}
          value={form.values.newPassword}
          onChange={form.handleChange}
          hasError={form.errors.newPassword}
        />
        <PasswordForce password={form.values.newPassword} />
        <Input
          className='confirmation'
          name='confirmationNewPassword'
          label='Repetir nova senha'
          type='password'
          maxLength={MAXIMUM_PASSWORD_LENGTH}
          value={form.values.confirmationNewPassword}
          onChange={form.handleChange}
          hasError={form.errors.confirmationNewPassword}
        />
        {!!error && <p className='error'>{error.title}</p>}
        <Button
          className='button'
          type='submit'
          color='primary'
          size='large'
          isLoading={form.isSubmitting}
        >
          Criar nova senha
        </Button>
      </form>
    </div>
  )
}
