import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { useForm, getCallsHistory, postEvent, useFirebase } from 'hooks'
import { formatDate, formatPhone, subtractDays, toISO } from 'utils'
import { EVENTS, FIREBASE_EVENTS } from 'app-constants'
import { Card } from 'components/card'
import { Input } from 'components/input'
import { Button } from 'components/button'
import { ContextLoader } from 'components/context-loader'
import { setDrawerAlert } from 'slices/drawer'
import { STATICS } from 'statics'
import { CALLS_HISTORY_ERROR } from '../../constants'
import './style.scss'

const validationSchema = Yup.object().shape({
  initial_date: Yup.string().required(),
  final_date: Yup.string().required(),
})

export function CallsHistory() {
  const [calls, setCalls] = useState([])
  const [loading, setLoading] = useState(false)
  const { sendEvent } = useFirebase()
  const dispatch = useDispatch()

  const {
    selected: { id },
  } = useSelector(state => state.contract)

  const form = useForm({
    initialValues: {
      initial_date: formatDate(subtractDays(new Date(), 5)),
      final_date: formatDate(new Date()),
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    loadCallsHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.TELEPHONY_CALL_HISTORY)
  }, [sendEvent])

  async function loadCallsHistory() {
    try {
      setLoading(true)
      postEvent(id, EVENTS.PHONE_HISTORY_DETAILING_DOWNLOAD)
      const { initial_date, final_date } = form.values
      const initialDate = toISO(initial_date)
      const finalDate = toISO(final_date)

      const response = await getCallsHistory(id, initialDate, finalDate)

      const data = response.data.map(item => ({
        ...item,
        formattedPhoneNumber: formatPhone(item.phoneNumber.slice(2)),
        formattedDate: formatDate(item.callDate),
      }))

      setCalls(data)
    } catch (err) {
      console.error(err)
      dispatch(setDrawerAlert({ severity: 'error', title: CALLS_HISTORY_ERROR }))
    } finally {
      setLoading(false)
    }
  }

  async function handleSubmit() {
    loadCallsHistory()
  }

  if (loading) return <ContextLoader fixed={false} />

  return (
    <div className='calls-history-component'>
      <h3 className='title'>Histórico de ligações</h3>
      {calls.length ? (
        <>
          <p className='text'>Filtrar por período</p>
          <form className='form' onSubmit={form.handleSubmit}>
            <Input
              className='input'
              name='initial_date'
              label='Data inicial'
              placeholder='Data inicial'
              onChange={form.handleChange}
              value={form.values.initial_date}
              mask='99/99/9999'
              hasError={form.errors.initial_date}
              size='small'
            />
            <p className='text'>a</p>
            <Input
              className='input'
              name='final_date'
              label='Data final'
              placeholder='Data final'
              onChange={form.handleChange}
              value={form.values.final_date}
              mask='99/99/9999'
              hasError={form.errors.final_date}
              size='small'
            />
            <Button type='submit' className='button' color='primary' outlined size='sm'>
              Filtrar
            </Button>
          </form>
          {calls.map((item, index) => (
            <Card className='card' key={String(index)}>
              <p className='text'>{item.formattedPhoneNumber}</p>
              <p className='date'>{item.formattedDate}</p>
            </Card>
          ))}
        </>
      ) : (
        <>
          <STATICS.INVOICES_HISTORIC className='icon' />
          <p className='message'>Você ainda não tem histórico de ligações</p>
        </>
      )}
    </div>
  )
}
