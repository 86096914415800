export const SERVICE_ORDER_TYPES = {
  ACTIVATION: 'ACTIVATION',
  ADDRESS_CHANGE: 'ADDRESS_CHANGE',
  REPAIR: 'REPAIR',
}

export const SO_OPEN = 'ST_OS_CRIADA'

export const SO_TYPES = {
  FINISHED: 'ORDEM EXECUTADA',
}
