import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setDrawer } from 'slices/drawer'
import { STATICS } from 'statics'
import { postEvent, useFirebase } from 'hooks'
import { EVENTS, FIREBASE_EVENTS } from 'app-constants'
import { Card } from '../card'
import { CardButton } from '../card-button'
import './style.scss'

export function PersonalInfoCard({ className }) {
  const { document, name } = useSelector(state => state.customer.info)
  const idContract = useSelector(state => state.contract.selected.id)
  const { sendEvent } = useFirebase()
  const dispatch = useDispatch()

  function handleOpenPhoneDrawer() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SEE_PHONES)
    postEvent(idContract, EVENTS.CLICK_ON_REGISTRATION_DATA)
    dispatch(setDrawer('phone'))
  }

  function handleOpenEmailDrawer() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SEE_EMAILS)
    postEvent(idContract, EVENTS.CLICK_ON_REGISTRATION_DATA)
    dispatch(setDrawer('email'))
  }

  return (
    <Card className={`personal-info-component ${className || ''}`}>
      <div className='wrapper'>
        <h3 className='title'>Meus dados cadastrais</h3>
        <p className='name'>{name}</p>
        <p className='document'>{document}</p>
      </div>
      <div className='wrapper -actions'>
        <CardButton icon={<STATICS.PHONE />} onClick={handleOpenPhoneDrawer}>
          Meus telefones
        </CardButton>
        <CardButton icon={<STATICS.ENVELOPE />} onClick={handleOpenEmailDrawer}>
          Meus e-mails
        </CardButton>
      </div>
    </Card>
  )
}
