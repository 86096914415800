import React from 'react'
import { Button } from 'components'
import { STATICS } from 'statics'
import { postEvent } from 'hooks'
import { useSelector } from 'react-redux'
import './style.scss'

export function DocumentLine({ name, urlFile, description, btnLabel = 'Baixar Documento', event }) {
  const {
    selected: { id: idContract },
  } = useSelector(state => state.contract)

  function renderDownload() {
    return (
      <Button
        className='button'
        icon={<STATICS.ALTERNATIVE_DOWNLOAD />}
        color='primary'
        onClick={handleClick}
      >
        {btnLabel}
      </Button>
    )
  }

  function handleClick() {
    postEvent(idContract, event)
    window.open(urlFile)
  }

  return (
    <div className='document-line-component'>
      <div className='wrapper'>
        <p className='field'>{name}</p>
      </div>
      <div className='wrapper'>
        <p className='field'>{description}</p>
      </div>
      <div className='actions'>{renderDownload()}</div>
    </div>
  )
}
